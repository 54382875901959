import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Redirect, Link } from "react-router-dom";
import { authStates, withAuth } from "../../auth";
import Image from "../../elements/Image";
import Tooltip from "@atlaskit/tooltip";
import Toggle from "@atlaskit/toggle";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBCardBody,
  MDBBtn,
  MDBCardText,
  MDBCard,
} from "mdb-react-ui-kit";
import {
  getUserProfle,
  disableTelegramNotification,
  updateCustomSetting,
} from "../../../utils/Client";

function UserProfile({ authState, user }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 868px)").matches,
  );
  const [showLoader, setShowLoader] = useState(false);
  const [profileInfo, setProfileInfo] = useState(undefined);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
  const [isColdplayEnabled, setIsColdplayEnabled] = useState(false);

  useEffect(() => {
    window
      .matchMedia("(min-width: 868px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  useEffect(async () => {
    if (authState === authStates.LOGGED_IN) {
      try {
        setShowLoader(true);
        const profileInfo = await getUserProfle();
        setProfileInfo(profileInfo);
        setIsNotificationEnabled(profileInfo?.notificationStatus == "ENABLED");
        setIsColdplayEnabled(profileInfo?.coldplayEnabled === true);
        setShowLoader(false);
      } catch (err) {
        toast.error("Error while fetching user profile");
        setShowLoader(false);
      }
    }
  }, [authState]);

  if (authState === authStates.INITIAL_VALUE) {
    return (
      <div className={"text-center"}>
        <MDBSpinner color="primary">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }

  if (authState === authStates.LOGGED_OUT) {
    return <Redirect to="/login"></Redirect>;
  }

  const isPremium = () => {
    return profileInfo && profileInfo?.license?.type !== "FREE";
  };

  const hasContent = () => {
    return !showLoader && profileInfo !== undefined;
  };

  const changeNotificationStatus = async () => {
    const currentStatus = isNotificationEnabled;
    let updatedStatus = !currentStatus;
    if (updatedStatus == false) {
      try {
        await disableTelegramNotification();
        toast.success("Notifications Disabled 📵 📵");
      } catch (err) {
        toast.error("Error while disabling notifications.");
        updatedStatus = currentStatus;
      }
    } else if (updatedStatus && profileInfo?.notificationToken !== undefined) {
      window.open(
        "https://telegram.me/themindmap_bot?start=" +
          profileInfo.notificationToken,
        "_blank",
      );
    }
    setIsNotificationEnabled(updatedStatus);
  };

  const changeColdplayEnabled = async () => {
    const currentStatus = isColdplayEnabled;
    let updatedStatus = !currentStatus;
    try {
      await updateCustomSetting("coldplay", updatedStatus);
      const suffix = updatedStatus === true ? "enabled 🚀🚀" : "disabled";
      toast.success("Coldplay notifications " + suffix);
    } catch (err) {
      toast.error(
        "Error while changing preference for coldplay notify settings.",
      );
      updatedStatus = currentStatus;
    }

    setIsColdplayEnabled(updatedStatus);
  };

  return (
    <MDBContainer
      fluid
      style={
        matches
          ? {
              paddingLeft: "15rem",
              paddingRight: "7rem",
              paddingTop: "6rem",
              paddingBottom: "2rem",
            }
          : { paddingTop: "4rem", paddingBottom: "2rem" }
      }
    >
      {showLoader && (
        <div
          className={"text-center"}
          style={{
            position: "fixed",
            left: "-10%",
            top: "30%",
            width: "100%",
            height: "100%",
            zIndex: "9999",
          }}
        >
          <MDBSpinner color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      )}
      {hasContent() && (
        <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText
                      style={{ fontWeight: "800", fontFamily: "sans-serif" }}
                    >
                      Full Name
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {profileInfo.name}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText
                      style={{ fontWeight: "800", fontFamily: "sans-serif" }}
                    >
                      Email
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {profileInfo.email}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText
                      style={{ fontWeight: "800", fontFamily: "sans-serif" }}
                    >
                      Friends
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {profileInfo.friends}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText
                      style={{ fontWeight: "800", fontFamily: "sans-serif" }}
                    >
                      Telegram Notification
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <Tooltip
                      content={
                        isNotificationEnabled
                          ? "Disable Telegram Notifications"
                          : "Enable Telegram Notifications"
                      }
                    >
                      <Toggle
                        id="toggle-large"
                        size="large"
                        onChange={changeNotificationStatus}
                        defaultChecked={isNotificationEnabled}
                      />
                    </Tooltip>
                    {!isNotificationEnabled && (
                      <span style={{ fontSize: "medium" }}>
                        {" "}
                        You will be redirected to Telegram to Enable
                        notifications.
                      </span>
                    )}
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText
                      style={{ fontWeight: "800", fontFamily: "sans-serif" }}
                    >
                      License Type
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9" style={{ marginBottom: "2rem" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <MDBCardText
                        className={
                          isPremium() == true
                            ? "tag-button-premium"
                            : "tag-button-free"
                        }
                        style={{ position: "absolute" }}
                      >
                        {profileInfo.license?.type}
                      </MDBCardText>
                      {isPremium() === false && (
                        <Link
                          to={{
                            pathname: "/upgrade",
                            state: {
                              text: "Back to Profile",
                              path: "/profile",
                            },
                          }}
                        >
                          <MDBBtn
                            className="btn-sm"
                            color="light"
                            style={{ position: "absolute", right: "1.5rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Image
                                src={require("./../../../assets/images/rocket.gif")}
                                alt="Upgrade Icon"
                                width={25}
                                height={25}
                              />
                              Upgrade Now
                            </div>
                          </MDBBtn>
                        </Link>
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText
                      style={{ fontWeight: "800", fontFamily: "sans-serif" }}
                    >
                      License Limit
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {profileInfo?.license?.limit} seats
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText
                      style={{ fontWeight: "800", fontFamily: "sans-serif" }}
                    >
                      Used Seats
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {profileInfo?.license?.usedSeats} seats
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText
                      style={{ fontWeight: "800", fontFamily: "sans-serif" }}
                    >
                      Notify for Coldplay India 2025
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <Tooltip
                      content={
                        !isNotificationEnabled
                          ? "Please enable telegram notifications first to change this"
                          : isColdplayEnabled
                            ? "Disable coldplay notifications"
                            : "Enable coldplay notifications"
                      }
                    >
                      <Toggle
                        id="toggle-large"
                        size="large"
                        onChange={changeColdplayEnabled}
                        defaultChecked={isColdplayEnabled}
                        isDisabled={!isNotificationEnabled}
                      />
                    </Tooltip>
                    {!isNotificationEnabled && (
                      <span style={{ fontSize: "medium" }}>
                        You need to enable telegram notifications once to enable
                        this
                      </span>
                    )}
                    {isNotificationEnabled && (
                      <span style={{ fontSize: "medium" }}>
                        You will get a notification in our telegram bot as soon
                        as Coldplay booking starts for their India tour
                      </span>
                    )}
                  </MDBCol>
                </MDBRow>
                {isPremium() && (
                  <>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText
                          style={{
                            fontWeight: "800",
                            fontFamily: "sans-serif",
                          }}
                        >
                          License Expiry Date
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {profileInfo?.license?.validTill}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      )}

      {!showLoader && (
        <h3 style={{ fontWeight: "300" }}>
          Thank you for using
          <span style={{ animation: "lights 5s 750ms linear infinite" }}>
            &nbsp;Mindmap
          </span>
          &nbsp;🙏
        </h3>
      )}
      <Toaster />
    </MDBContainer>
  );
}

export default withAuth(UserProfile);
