import React, {useState} from 'react';
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBBadge,
    MDBCardFooter,
    MDBIcon,
    MDBCardBody,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBRadio,
    MDBTextArea
  } from 'mdb-react-ui-kit';
  import Tag from '@atlaskit/tag';

  const MONTH_MAP = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

function Friend({friend, onDeleteFact, onAddFact, onEditFriend, canAddFacts}) {
    const [factModal, setFactModal] = useState(false)
    const [friendUpdateModal, setFriendUpdateModal] = useState(false)
    const [updatedFriendInfo, setUpdatedFriendInfo] = useState({...friend})
    const [newTag, setNewTag] = useState('');
    const initialFactValues = {
        type: "GENERAL",
        title: "",
        date: ""
    };
    
    const [newFact, setNewFact] = useState(initialFactValues)
    const toggleShow = (e) => {
        if (e) {
            e.preventDefault()
        }
        const radioBtns = document.querySelectorAll(
            "input[type='radio'][name='type']");
        radioBtns.forEach((radioBtn) => {
            if (radioBtn.id === 'inlineRadio2') {
                radioBtn.checked = false
            } else if (radioBtn.id === 'inlineRadio1') {
                radioBtn.checked = true;
            }
        });

        setNewFact(initialFactValues)
        setFactModal(!factModal)
    };

    const toggleFriendEditModal = (e) => {
        if (e) {
            e.preventDefault()
        }
        setFriendUpdateModal({...friend})
        setFriendUpdateModal(!friendUpdateModal)
    }

    const isNumeric = (str) => {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
    const validDate = (dd, value) => {
        if (dd === undefined) return true
        if (value.length === 1) {
            return dd >=0 && dd <=31
        } else {
            return dd >=1 && dd <=31
        }
    }
    const validMonth = (mm, value) => {
        if (mm === undefined) return true
        if (value.length === 4) {
            return mm >=0 && mm <=12
        } else {
            return mm >=1 && mm <=12
        }
    }

    const handleFriendInfoChangeChange = (e) => {
        const {name, value} = e.target;
        setUpdatedFriendInfo({
            ...updatedFriendInfo,
            [name]: value,
        });
    };

    const handleTagChange = event => {
        setNewTag(event.target.value);
    };
    const addTag = (e) => {
        e.preventDefault();
        if (newTag && newTag.length > 0) {
            let newArr = [...updatedFriendInfo.tags];
            newArr.push(newTag.toLowerCase())
            setUpdatedFriendInfo({
                ...updatedFriendInfo,
                ['tags']: newArr,
            });
            setNewTag('')
        }
    }
    const removeTag = (tag) => {
        let newArr = [...updatedFriendInfo.tags].filter(name => name !== tag.toLowerCase());
        setUpdatedFriendInfo({
            ...updatedFriendInfo,
            ['tags']: newArr,
        });
        console.log("removed : " + tag)
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === 'date' && value.length > 0) {
            // validate input date
            if (!isNumeric(value.replace("/", ""))) {
                return
            }
            if (value.length>5) {
                return
            }
            const arr = value.split("/")
            const dd = arr[0].length > 0 ? parseInt(arr[0]) : undefined
            const mm = arr.length > 1 && arr[1].length>0 ? parseInt(arr[1]) : undefined
            if (!validDate(dd, value) || !validMonth(mm, value)) {
                return
            }
        }
        if (name === 'date' && value.length === 2 && newFact.date.length < 2) {
            setNewFact({
                ...newFact,
                [name]: value + "/",
            });
        } else if (name === 'date' && value.length === 3 && newFact.date.length > 3) {
            setNewFact({
                ...newFact,
                [name]: value.substring(0,2)
            });
        } else {
            setNewFact({
                ...newFact,
                [name]: value,
            });
        }

    };
    const addFact = async (e) => {
        e.preventDefault()
        console.log(newFact)
        toggleShow()
        await onAddFact(friend.id, newFact)  
    }


    if (!friend) {
        return <></>
    }

    const deleteFact = async (e, fid, factId) => {
        e.preventDefault();
        console.log("removing fact: " + factId)
        await onDeleteFact(fid, factId)
    }

    const editFriendInfo = async (e) =>{
        e.preventDefault();
        console.log(updatedFriendInfo)
        toggleFriendEditModal();
        await onEditFriend(updatedFriendInfo)  
    }

    const getDateStr = (date) => {
        const split = date.split("/")
        const monthName = MONTH_MAP[parseInt(split[1])]
        return split[0] + " " + monthName
    }

    const ShowFacts = ({facts}) => {
              return (
                <>
                {facts.map((fact, index) => {
                    let titletext = fact.title
                    let dtStr = undefined
                    if (fact.type === "TIMED" && fact.date) {
                        titletext += " on "
                        dtStr = getDateStr(fact.date)
                    }
                    return  <MDBCard key={index} style={{boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.5)', borderRadius: '0rem'}} className={(index === 0) ? 'bg-dark' : 'mt-1 bg-dark'}>
                                 <MDBCardBody style={{padding: '0.8rem 1.5rem'}}>
                                    <p className="mb-1" style={{color: '#e1f2f0', fontSize: '90%'}}>
                                        {titletext}
                                        {dtStr && <span style={{fontWeight: "bold"}}> {dtStr} </span>}
                                    </p>
                                   <MDBBtn outline className='btn-sm' color='light' 
                                            style={{marginTop: '1rem'}}
                                            onClick={(e) => deleteFact(e, friend.id, fact.id)}>
                                        Remove
                                    </MDBBtn>
                                </MDBCardBody>
                             </MDBCard>
                
                })}
                </>           
              )                       
    }

    const hasFacts = friend.facts && friend.facts.length > 0;
    return (
        <>
            <MDBContainer className="py-2 py-md-5">
                <MDBRow>
                    <MDBCol lg="4">
                        <MDBCard className="mb-4" style={{backgroundColor: '#F3EFE0'}}>
                            <MDBCardBody className="text-center">
                                <p className="text-muted mb-1" style={{fontWeight: "bold", paddingTop: "25px"}}>{friend.name}</p>
                                <p className="text-muted mb-4"  style={{fontSize: "84%"}}>{friend.description}</p>
                                <div className="d-flex justify-content-center mb-2">
                                <MDBBtn outline className="ms-1" onClick={toggleFriendEditModal}>
                                    <MDBIcon far icon="edit" /> Edit
                                </MDBBtn>
                                <MDBBtn outline className="ms-1" onClick={toggleShow}>
                                    <MDBIcon far icon="plus-square" /> Add Fact
                                </MDBBtn>
                                </div>
                            </MDBCardBody>
                            <MDBCardFooter>
                                <div className='text-muted' style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    {friend.tags.map((tag, index) => {
                                        return  <MDBBadge className={(index === 0) ? 'bg-info' : 'mx-1 bg-info'}> {tag} </MDBBadge>
                                    
                                    })}
                                </div>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                    {!hasFacts && (
                         <MDBCol lg="8">
                            <div style={{textTransform: "unset", border: "0.5px solid", borderStyle: "dashed", width:"100%", textAlign: 'center', padding: '2rem'}}>
                                Please add details of <span style={{fontWeight: "bold"}}> {friend.name} </span>  by clicking <a href="#" onClick={toggleShow}>here</a>
                            </div>
                        </MDBCol>
                    )}
                    {hasFacts && (
                        <>
                         <MDBCol lg="4" className='mt-1'>
                            <ShowFacts facts={friend.facts.filter((e,i)=>!(i%2))}/>
                        </MDBCol>
                        <MDBCol lg="4"  className='mt-1'>
                            <ShowFacts facts={friend.facts.filter((e,i)=>(i%2))}/>
                        </MDBCol>
                        </>
                    )}
                   
                </MDBRow>

                <MDBModal show={factModal} setShow={setFactModal} tabIndex="-1" dark>
                <MDBModalDialog className="bg-dark">
                    <MDBModalContent className="p-2 bg-dark">
                        <MDBModalHeader>
                            <MDBModalTitle>Add fact about {friend.name}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        {canAddFacts && <form onSubmit={addFact}>
                            <MDBModalBody>

                                <MDBTextArea label='Title' contrast style={{margin: '1rem'}}
                                             onChange={handleInputChange} value={newFact.title} autoComplete="off"
                                             name="title" required/>
                                <MDBRadio name='type' onChange={handleInputChange} id='inlineRadio1' value='GENERAL' label='General' inline required defaultChecked/>
                                <MDBRadio name='type' onChange={handleInputChange} id='inlineRadio2' value='TIMED' label='Timed' inline required/>
                                {newFact.type === "TIMED" && (
                                    <MDBInput label='Select Date' contrast style={{margin: '1rem'}}
                                              placeholder="DD / MM"
                                              required = {newFact.type === "TIMED" ? true : false}
                                              onChange={handleInputChange} value={newFact.date} autoComplete="off"
                                              name="date"/>
                                )}
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn outline className='btn-sm' color='light' onClick={(e) => {
                                    e.preventDefault();
                                    toggleShow()
                                }}>
                                    Close
                                </MDBBtn>
                                <MDBBtn outline className='btn-sm' color="light" type="submit">Save</MDBBtn>
                            </MDBModalFooter>
                        </form>}
                        {!canAddFacts && <div className="m-4">
                            <div>You can only add 25 facts (combined) with the FREE account. </div>
                            <div className="mt-3">Please <a href="/upgrade"> upgrade to PREMIUM </a> to add more facts</div>
                        </div>}
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            <MDBModal show={friendUpdateModal} setShow={setFriendUpdateModal} tabIndex="-1" dark>
                <MDBModalDialog className="bg-dark">
                <MDBModalContent className="p-2 bg-dark">
                        <MDBModalHeader>
                            <MDBModalTitle>Edit Details</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleFriendEditModal}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={editFriendInfo}>
                            <MDBModalBody>

                                <MDBInput label='Full Name' contrast style={{margin: '1rem'}}
                                          onChange={handleFriendInfoChangeChange} value={updatedFriendInfo.name} autoComplete="off"
                                          name="name" required/>
                                <MDBInput label='Relation' contrast style={{margin: '1rem'}}
                                          onChange={handleFriendInfoChangeChange} value={updatedFriendInfo.relation} autoComplete="off"
                                          name="relation"/>
                                <MDBTextArea label='Description' contrast style={{margin: '1rem'}}
                                          onChange={handleFriendInfoChangeChange} value={updatedFriendInfo.description} autoComplete="off"
                                          name="description"/>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <MDBInput label='Tags' onChange={handleTagChange} value={newTag} autoComplete="off"
                                              contrast/>
                                    <MDBBtn color='info' size='sm' style={{marginLeft: '0.5rem'}}
                                            onClick={addTag}>Add</MDBBtn>
                                </div>
                                <div className="col-xl">
                                    {updatedFriendInfo.tags.map(tag => {
                                        return <Tag color='teal' text={tag} removeButtonLabel="Remove"
                                                    onAfterRemoveAction={removeTag}/>
                                    })}
                                </div>


                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='light' onClick={(e) => {
                                    e.preventDefault();
                                    toggleFriendEditModal()
                                }}>
                                    Close
                                </MDBBtn>
                                <MDBBtn type="submit">Update</MDBBtn>
                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            </MDBContainer>
        </>
    )
}

export default Friend;
