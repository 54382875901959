import React, {useState, useEffect} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {authStates, withAuth} from "../../auth";
import {MDBContainer, MDBInput, MDBSpinner, MDBBtn, MDBIcon,
    MDBCardBody,
    MDBRow, MDBCol, MDBCard, MDBCardTitle
} from "mdb-react-ui-kit";

import {getUserInfo, upgradeUser} from "../../../utils/Client";

import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Upgrade({authState, user}) {
    const [licenseKey, setLicenseKey] = useState('')
    const [showLoginError, setShowLoginError] = useState(false)
    const [userInfo, setUserInfo] = useState()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 868px)").matches
    )

    const history = useHistory();
    const gumroadPage = "https://thatman.gumroad.com/l/mindmap"

    useEffect(() => {
        window
            .matchMedia("(min-width: 868px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    useEffect(async () => {
        if (authState === authStates.LOGGED_IN) {
            setShowLoader(true)
            setIsLoggedIn(true)
            try {
                const userInfo = await getUserInfo()
                setUserInfo(userInfo)
            } catch(err) {
                toast.error('Error fetching user information');    
            }
            setShowLoader(false)
        }
    }, [authState])

    if (authState === authStates.INITIAL_VALUE) {
        return (
            <div className={"text-center"}>
                <MDBSpinner color='primary'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
        );
    }

    const handleLicenseKeyChange = event => {
        setLicenseKey(event.target.value);
      };

    const upgradeToPremium = async (e) => {
        e.preventDefault()
        console.log("license key=" + licenseKey)
        if (!isLoggedIn) {
            setShowLoginError(true)
        } else {
            setShowLoader(true)
            try {
              const updatedUser = await upgradeUser(licenseKey)
              toast.success("Thank you for Upgrading 😀")
              setTimeout(() => {return history.push("/dashboard");}, 3000)
            } catch(err) {
              let msg = err?.response?.data?.message
              if (!msg) msg = "Please try again in sometime"
              toast.error(msg);
            }
            setLicenseKey('')
            setShowLoader(false)
        }
    }

    const openGumroad = () => {
        window.open(gumroadPage, '_blank');
    }

    return (
        <MDBContainer fluid style={(matches)? {paddingLeft: '7rem', paddingRight: '7rem', paddingTop: '4rem', paddingBottom: '2rem'} : {paddingTop: '6rem', paddingBottom: '2rem'}}>
            {showLoader && (
                <div className={"text-center"}
                     style={{position: 'fixed', left: '0%', top: '30%', width: '100%', height: '100%', zIndex: '9999'}}>
                    <MDBSpinner color='primary'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>
            )}
            <div className="hero-content">
               <h2 className="mt-0 mb-16 text-center">
                    Upgrade to <span className="text-color-primary">Premium</span>
                </h2>

                <MDBRow className='mt-4'>
        <MDBCol md="6" className="mb-6">
          <MDBCard className="bg-dark">
            
            <MDBCardBody style={{padding: "1rem", paddingTop:"0.2rem"}}>
              <div className="text-center">
                <MDBCardTitle className="h3 text-info">Free</MDBCardTitle>
                <h6 className="text-danger" style={{fontWeight: "500"}}>No Payment Required</h6>
              </div>
              <hr className="hr" style={{backgroundColor: "aliceblue"}}/>
              <div className="text-center">
              <div className='d-flex' style={{justifyContent: "space-between"}}>
                <div className="d-flex flex-column mb-4">
                  <span className="h4 mt-2 mb-0">15</span>
                  <span>User Seats</span>
                </div>
                <div className="d-flex flex-column mb-4">
                  <span className="h4 mt-2 mb-0">25</span>
                  <span>Total Facts</span>
                </div>
                </div>
                <div style={{textAlign: "left"}}>
                    <div className="d-flex flex-column mb-2">
                    <span>✅ Email Notifications</span>
                    </div>
                    <div className="d-flex flex-column mb-2">
                    <span>✅ Telegram Notifications</span>
                    </div>
                </div>
              </div>
              <Link to="/dashboard">
              <div className="d-flex flex-row mt-3">
                <MDBBtn
                  color="info"
                  rippleColor="dark"
                  className="flex-fill ms-1"
                >
                  Get Started Now
                </MDBBtn>
              </div>
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
       
        <MDBCol md="6" className="mb-4">
          <MDBCard className="bg-dark">
        
            <MDBCardBody style={{padding: "1rem", paddingTop:"0.2rem"}}>
              <div className="text-center">
                <MDBCardTitle className="h3 text-info">Premium 🚀</MDBCardTitle>
                <h6 className="text-danger" style={{fontWeight: "500"}}>$15 / year</h6>
              </div>
              <hr className="hr" style={{backgroundColor: "aliceblue"}}/>
              <div className="text-center">
        
                <div className='d-flex' style={{justifyContent: "space-between"}}>
                    <div className="d-flex flex-column mb-4">
                    <span className="h4 mt-2 mb-0">99</span>
                    <span>User Seats</span>
                    </div>
                    <div className="d-flex flex-column mb-4">
                    <span className="h4 mt-2 mb-0">Unlimited</span>
                    <span>Total Facts</span>
                    </div>
                </div>

                <div style={{textAlign: "left"}}>
                    <div className="d-flex flex-column mb-2">
                    <span>✅ Email Notifications</span>
                    </div>
                    <div className="d-flex flex-column mb-2">
                    <span>✅ Telegram Notifications</span>
                    </div>
                </div>


              </div>

              <div className="d-flex flex-row mt-3">
                <MDBBtn
                  color="info"
                  rippleColor="dark"
                  className="flex-fill ms-1"
                  onClick={openGumroad}
                >
                    Pay on Gumroad &nbsp;
                    <MDBIcon icon="angle-double-right" size="lg" className="indigo-text pr-3 mr-3" />
                  
                </MDBBtn>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
            </MDBRow>
            <div className="container-sm">
                <p className="m-0 mb-3 text-center mt-0">
                 We are currently supporting payments via <a href={gumroadPage} target="_blank">Gumroad</a>. You can complete the checkout to get a premium license key.
                </p>
            </div>
            <div className="container-sm mt-0">
                <form onSubmit={upgradeToPremium}>
                        <div className='d-flex justify-content-center mb-2'>
                            <MDBInput label='Premium license key' id='waitlistBox' onChange={handleLicenseKeyChange} required type='text' contrast name={licenseKey} value={licenseKey}/>
                            <MDBBtn outline className='btn mx-2' color="light" type="submit">Upgrade</MDBBtn>
                        </div>
                        {showLoginError && <a href="/login"><u style={{color: "#A30000"}} ><span className='d-flex justify-content-center mb-2'>Please login first</span></u></a>}
                </form>
                {isLoggedIn && (
                    <div className='d-flex justify-content-center mb-2' style={{marginTop: '2rem'}}>
                        <Link to="/dashboard">
                        <MDBIcon icon="arrow-circle-left" size="lg" className="indigo-text pr-3 mr-3" /> &nbsp;
                            <u>
                                Back to Dashboard
                            </u>
                        </Link>
                    </div>
                )}
            </div>
            </div>


        
                    
            <Toaster />
        </MDBContainer>
    )
}

export default withAuth(Upgrade);