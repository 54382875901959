import React, {useState, useEffect} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {authStates, withAuth} from "../../auth";
import {MDBContainer, MDBInput, MDBSpinner} from "mdb-react-ui-kit";
import {Redirect} from "react-router-dom";
import {getUserInfo, addFriendForUser, deleteFriendForUser, deleteFriendFact, addFriendFact, editFriend} from "../../../utils/Client";
import FriendGrid from "./FriendGrid";
import Friend from './Friend';

function Dashboard({authState, user}) {
    const [userInfo, setUserInfo] = useState()
    const [friends, setFriends] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [query, setQuery] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [showFriendDetail, setShowFriendDetail] = useState(false)
    const [currentFriend, setCurrentFriend] = useState(undefined)

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 868px)").matches
    )

    useEffect(() => {
        document.getElementById("dashboardBtn").addEventListener('click', function(e) {
            setShowFriendDetail(false)
        });
        window
            .matchMedia("(min-width: 868px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    useEffect(() => {
        const getData = setTimeout(() => {
            if (query && query.length > 0) {
                setShowLoader(true)
                setTimeout(() => {
                    const updatedFriends = friends.filter(f => {
                        if (f.name.toLowerCase().includes(query.toLowerCase())) {
                            return true
                        }
                        if (f.tags) {
                            const tags = f.tags.filter(tag => tag.toLowerCase().includes(query.toLowerCase()))
                            return tags.length > 0
                        }
                    })
                    setSearchResults(updatedFriends)
                    if (!matches) {
                        document.getElementById('search').blur()
                    }
                    setShowLoader(false)
                }, 500)
            } else if (friends && friends.length > 0) {
                setSearchResults(friends)
            }
        }, 1000)
        return () => clearTimeout(getData)
    }, [query])
    useEffect(async () => {
        if (authState === authStates.LOGGED_IN) {
            setShowLoader(true)
            try {
                const userInfo = await getUserInfo()
                setFriends(userInfo.friends)
                setSearchResults(userInfo.friends)
                setUserInfo(userInfo)
                // This is to directly open friend detail page for testing
                // setCurrentFriend(userInfo.friends[0])
                // setShowFriendDetail(true)
            } catch(err) {
                toast.error('Error while fetching data');    
            }
            setShowLoader(false)
        }
    }, [authState])

    if (authState === authStates.INITIAL_VALUE) {
        return (
            <div className={"text-center"}>
                <MDBSpinner color='primary'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
        );
    }

    if (authState === authStates.LOGGED_OUT) {
        return <Redirect to="/login"></Redirect>;
    }

    const addFriend = async (friend) => {
        setShowLoader(true)
        const updatedUser = await addFriendForUser(friend)
        setShowLoader(false)
        setFriends(updatedUser.friends)
        setSearchResults(updatedUser.friends)
    }

    const deleteFriend = async (fid) => {
        setShowLoader(true)
        const updatedUser = await deleteFriendForUser(fid)
        setShowLoader(false);
        setFriends(updatedUser.friends)
        setSearchResults(updatedUser.friends)
    }

    const deleteFact = async (fid, factId) => {
        setShowLoader(true)
        const updatedUser = await deleteFriendFact(fid, factId)
        setShowLoader(false);
        setSearchResults(updatedUser.friends)
        const updatedFriend = updatedUser.friends.find(o => o.id === fid);
        setCurrentFriend(updatedFriend)
    }

    const editFriendInfo = async (friendInfo) => {
        setShowLoader(true)
        try {
            const updatedUser = await editFriend(friendInfo)
            setShowLoader(false);
            setUserInfo(updatedUser)
            setFriends(updatedUser.friends)
            setSearchResults(updatedUser.friends)
            const updatedFriend = updatedUser.friends.find(o => o.id === friendInfo.id);
            setCurrentFriend(updatedFriend)
        } catch(err) {
            const msg = err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong"
            setShowLoader(false);
            setCurrentFriend(friends.find(o => o.id === friendInfo.id))
            toast.error(msg)
        }
    }

    const addFact = async (fid, fact) => {
        setShowLoader(true)
        setTimeout(async () => {
            try {
                const updatedUser = await addFriendFact(fid, fact)
                setShowLoader(false);
                setSearchResults(updatedUser.friends)
                const updatedFriend = updatedUser.friends.find(o => o.id === fid);
                setCurrentFriend(updatedFriend)
            } catch(err) {
                const msg = err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong"
                setShowLoader(false);
                setCurrentFriend(friends.find(o => o.id === fid))
                toast.error(msg)
            }
        }, 500);
    }

    const showFriend = (f) => {
        setCurrentFriend(f)
        setShowFriendDetail(true)
        setQuery('')
    }

    const getUserName = () => {
        if (user && user.displayName) {
            return user.displayName;
        } else if (user && user.email) {
            return user.email
        }
        return "User"
    }
    const welcomeText = friends && friends.length > 0 ? "Welcome back " : "Welcome "
    const getUpgradeText = () => {
        if (userInfo && userInfo.licenseType === "FREE") {
            const remaining = 15 - friends.length
            if (remaining === 0) {
                return "You have no more space available in your mindmap. "
            } else if (remaining > 0) {
                return "You can add " + remaining + " more people to your mindmap. "
            }
        }
        return ""
    }

    const canAddMoreFriends = () => {
        if (userInfo && friends && userInfo.licenseType === "FREE") {
            return friends.length < 15
        }
        if (userInfo && friends && userInfo.licenseType === "PREMIUM") {
            return friends.length < 99
        }
        return false;
    }
    const canAddMoreFacts = () => {
        if (userInfo && friends && userInfo.licenseType === "PREMIUM") {
            return true
        }
        if (userInfo && friends && userInfo.licenseType === "FREE") {
            const totalCards = friends.reduce((accumulator, frnd) => {
                const fc = frnd.facts ? frnd.facts.length : 0
                return accumulator + fc;
            }, 0);
            return totalCards < 25
        }

        return false
    }
    const userName = getUserName();
    return (
        <MDBContainer fluid style={(matches)? {paddingLeft: '7rem', paddingRight: '7rem', paddingTop: '4rem', paddingBottom: '2rem'} : {paddingTop: '6rem', paddingBottom: '2rem'}}>
            {showLoader && (
                <div className={"text-center"}
                     style={{position: 'fixed', left: '0%', top: '30%', width: '100%', height: '100%', zIndex: '9999'}}>
                    <MDBSpinner color='primary'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>
            )}
            {showFriendDetail && (
                <Friend friend={currentFriend} onDeleteFact={deleteFact} onAddFact={addFact} onEditFriend={editFriendInfo} canAddFacts={canAddMoreFacts()}/>
            )}

            {!showFriendDetail && (
                <div>
                    <div className="text-center"> {welcomeText} <span style={{fontWeight: "bold"}}>{userName} 👋</span></div>
                    {!friends || friends.length ===0 && (
                          <div className="text-center mt-2" style={{fontSize: "85%"}}> Go ahead and add your first friend.</div>
                    )}
                    {friends && friends.length > 5 && userInfo?.licenseType === "FREE" && (
                        <div className="text-center mt-2" style={{fontSize: "85%"}}> {getUpgradeText()} <a href="/upgrade" target="_blank"><span style={{fontWeight: "bold", fontSize: "85%"}}>Upgrade Now</span></a></div>
                    )}
                    <div>
                        <MDBInput
                            id='search'
                            label='Search your mindmap by name or tags'
                            style={{margin: '1rem'}}
                            contrast
                            autoComplete="off"
                            onChange={(event) => setQuery(event.target.value)}/>
                    </div>
                    <FriendGrid friends={searchResults} addFriend={addFriend} deleteFriend={deleteFriend} showFriend={showFriend} canAddMore={canAddMoreFriends()}/>
                </div>
            )}
        <Toaster />
        </MDBContainer>
    )
}

export default withAuth(Dashboard);