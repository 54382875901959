import React, {useState, useEffect} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardFooter,
    MDBRow,
    MDBCol,
    MDBBadge,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBTextArea
} from 'mdb-react-ui-kit';
import Tag from '@atlaskit/tag';


function FriendGrid({friends, addFriend, deleteFriend, showFriend, canAddMore}) {
    const [basicModal, setBasicModal] = useState(false);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false)
    const [newTag, setNewTag] = useState('');
    const [friendToRemove, setFriendToRemove] = useState('')
    const [horizontal, setHorizontal] = useState(
        window.matchMedia("(min-width: 1100px)").matches
    )


    useEffect(() => {
        window
            .matchMedia("(min-width: 1100px)")
            .addEventListener('change', e => setHorizontal( e.matches ));
    }, []);

    const initialValues = {
        name: "",
        relation: "",
        description: "",
        tags: [],
    };
    const [newFriend, setNewFriend] = useState(initialValues)

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setNewFriend({
            ...newFriend,
            [name]: value,
        });
    };

    const toggleShow = (e) => {
        if (e) {
            e.preventDefault()
        }
        setNewFriend(initialValues)
        setBasicModal(!basicModal)
    };
    const handleTagChange = event => {
        setNewTag(event.target.value);
    };
    const addTag = (e) => {
        e.preventDefault();
        if (newTag && newTag.length > 0) {
            let newArr = [...newFriend.tags];
            newArr.push(newTag.toLowerCase())
            setNewFriend({
                ...newFriend,
                ['tags']: newArr,
            });
            setNewTag('')
        }
    }

    const removeTag = (tag) => {
        let newArr = [...newFriend.tags].filter(name => name !== tag.toLowerCase());
        setNewFriend({
            ...newFriend,
            ['tags']: newArr,
        });
        console.log("removed : " + tag)
    }

    const onAddFriend = async (e) => {
        e.preventDefault()
        toggleShow()
        await addFriend(newFriend)
    }

    const onShowFriend = (e, f) => {
        e.preventDefault()
        showFriend(f)
    }

    const onDeleteFriend = async (e, fid) => {
        e.preventDefault()
        setFriendToRemove(fid)
        setShowRemoveDialog(true)
    }

    const getDescription = (desc) => {
        let arr = desc.split(" ")
        let msg =  arr.slice(0, 6).join(" ")
        if (arr.length > 6) {
            msg += "..."
        }
        return msg;
    }

    const addFriendText = canAddMore ? "Click here to add new friend 🧑 👩" : "Upgrade to add more people"
    return (
        <>
            <MDBRow className={horizontal? 'row-cols-1 row-cols-md-4 g-4' : 'row-cols-1 row-cols-md-3 g-4'}>
                <MDBCol>
                    <MDBCard className='h-100 bg-dark bg-opacity-25'>
                        <MDBCardBody>
                            <MDBCardTitle> </MDBCardTitle>
                            <MDBCardText>
                                {addFriendText}
                            </MDBCardText>
                            <a href="#" className={canAddMore ? "btn btn-dark btn-sm" : "btn btn-dark btn-sm disabled"}onClick={toggleShow}
                               style={{textTransform: "unset", border: "0.5px solid", borderStyle: "dashed", width:"100%"}}>Enter Details</a>
                        </MDBCardBody>
                        <MDBCardFooter> </MDBCardFooter>

                    </MDBCard>
                </MDBCol>
                {friends.map((f, index) => {
                    return (
                        <MDBCol key={index}>
                            <MDBCard className='h-100 bg-dark ftile rainbow-border' style={{boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.5)'}}>
                                <MDBCardBody className="pt-2">
                                    <MDBCardTitle>{f.name}</MDBCardTitle>
                                    <MDBCardText>
                                       <span style={{fontSize: "85%"}}> {getDescription(f.description)} </span>
                                    </MDBCardText>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: "space-between"
                                    }}>
                                        <a href="#" className="btn btn-primary btn-sm btnx btn-details"
                                           style={{textTransform: "unset", position: "absolute", "bottom" : "3rem", backgroundColor: "#5584AC"}}
                                           onClick={(e) => onShowFriend(e, f)}>Details</a>

                                        <a href="#" className="btn btn-danger btn-sm outline btnx btn-remove"
                                           style={{textTransform: "unset", position: "absolute", "bottom" : "3rem", right: "1.5rem", backgroundColor: "#6998AB"}}
                                           onClick={(e) => onDeleteFriend(e, f.id)}>Remove</a>
                                    </div>

                                </MDBCardBody>
                                <MDBCardFooter>
                                    <div className={f.tags && f.tags.length > 0 ? 'text-muted' : 'text-muted mt-4'} style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}>
                                        {f.tags && f.tags.slice(0,2).map((tag, index) => {
                                            return  <MDBBadge key={index} className={(index === 0) ? '' : 'mx-1'} style={{ fontWeight: "200", fontFamily: '"Inter", sans-serif', background: 'linear-gradient(to right, rgb(42 51 62 / 50%), rgb(45 40 50 / 50%))' }}>
                                                        {tag}
                                                    </MDBBadge>

                                        })}
                                        { f.tags && f.tags.length > 2 && (<MDBBadge style={{ fontWeight: "200", fontFamily: '"Inter", sans-serif', background: 'linear-gradient(to right, rgb(42 51 62 / 50%), rgb(45 40 50 / 50%))' }}>+{f.tags.length-2} more </MDBBadge>)}
                                    </div>
                                </MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    )
                })}
            </MDBRow>
            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1" dark>
                <MDBModalDialog className="bg-dark">
                    <MDBModalContent className="p-2 bg-dark">
                        <MDBModalHeader>
                            <MDBModalTitle>Add New Friend</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={onAddFriend}>
                            <MDBModalBody>

                                <MDBInput label='Full Name' contrast style={{margin: '1rem'}}
                                          onChange={handleInputChange} value={newFriend.name} autoComplete="off"
                                          name="name" required/>
                                <MDBInput label='Relation' contrast style={{margin: '1rem'}}
                                          onChange={handleInputChange} value={newFriend.relation} autoComplete="off"
                                          name="relation"/>
                                <MDBTextArea label='Description' contrast style={{margin: '1rem'}}
                                          onChange={handleInputChange} value={newFriend.description} autoComplete="off"
                                          name="description"/>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <MDBInput label='Tags' onChange={handleTagChange} value={newTag} autoComplete="off"
                                              contrast/>
                                    <MDBBtn color='info' size='sm' style={{marginLeft: '0.5rem'}}
                                            onClick={addTag}>Add</MDBBtn>
                                </div>
                                <div className="col-xl">
                                    {newFriend.tags.map(tag => {
                                        return <Tag color='teal' text={tag} removeButtonLabel="Remove"
                                                    onAfterRemoveAction={removeTag}/>
                                    })}
                                </div>


                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='light' onClick={(e) => {
                                    e.preventDefault();
                                    toggleShow()
                                }}>
                                    Close
                                </MDBBtn>
                                <MDBBtn type="submit">Save changes</MDBBtn>
                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal show={showRemoveDialog} setShow={setShowRemoveDialog} tabIndex="-1" dark>
                <MDBModalDialog className="bg-dark">
                    <MDBModalContent className="p-2 bg-dark">
                        <MDBModalHeader>
                            <MDBModalTitle>Remove Friend</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={() => {
                                setFriendToRemove('')
                                setShowRemoveDialog(false)
                            }}></MDBBtn>
                        </MDBModalHeader>
                        <form>
                            <MDBModalBody>
                                Do you want to remove this friend from your mindmap ?
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='light' onClick={(e) => {
                                    e.preventDefault()
                                    setFriendToRemove('')
                                    setShowRemoveDialog(false)
                                }}>
                                    Close
                                </MDBBtn>
                                <MDBBtn type="submit"
                                        color='danger'
                                        onClick={async (e) => {
                                            e.preventDefault()
                                            await deleteFriend(friendToRemove)
                                            setFriendToRemove('')
                                            setShowRemoveDialog(false)
                                        }}>Confirm</MDBBtn>
                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </>
    );
}

export default FriendGrid;