import React from 'react';
import DashboardHeader from '../components/layout/DashboardHeader';

const NotificationLayout = ({ children }) => (
    <>
        <DashboardHeader navPosition="right" className="reveal-from-bottom" selected={"Notification"}/>
        <main className="site-content">
            {children}
        </main>
    </>
);

export default NotificationLayout;