import React, {useState} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import classNames from 'classnames';
import {MDBInput, MDBBtn} from "mdb-react-ui-kit";
import { SectionProps } from '../../../utils/SectionProps';
import {signUpForEarlyAccess} from "../../../utils/Client";
const propTypes = {
    ...SectionProps.types
  }
  
  const defaultProps = {
    ...SectionProps.defaults
  }

function Pricing({ 
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) {
    const [email, setEmail] = useState('')
    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
      );

    const joinWaitlist = async (e) => {
      e.preventDefault()
      console.log("join waitlist for " + email)
      try {
        const res = await signUpForEarlyAccess(email)
        toast.success("Thank You 😀")
      } catch(err) {
        toast.error('Please try again in sometime');
      }
      setEmail('')
    }

    const handleEmailChange = event => {
      setEmail(event.target.value);
    };
    
    return (
        <section
        {...props}
        className={outerClasses}
      >
             <div className="container-sm">
               <div className={innerClasses}>
                <div className="hero-content">
                  <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                    Please join our <span className="text-color-primary">waitlist</span>
                  </h2>
                  <div className="container-xs">
                    <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                    Mindmap is currently in beta launch stage. Please sign up for early access to the premium version.
                   </p>
                  </div>
                  <div className="container-xs mt-5">
                    <form onSubmit={joinWaitlist}>
                      <div className='d-flex justify-content-center mb-2'>
                        <MDBInput label='Email' id='waitlistBox' onChange={handleEmailChange} required type='email' contrast name={email} value={email}/>
                        <MDBBtn outline className='btn mx-2' color="light" type="submit">Join</MDBBtn>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
             </div>
             <Toaster />
        </section>
        
    )
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;
export default Pricing;