import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }}
    >
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={require('./../../../assets/images/kog.png')}
            alt="Open"
            width={55}
            height={55} />
        </Link>
      </h1>
      <Link to="/">
        <h6 style={{letterSpacing: '0.2rem', background: 'linear-gradient(to right, #30CFD0 0%, #bd9ce4 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>MINDMAP</h6>
      </Link>
    </div>
  );
}

export default Logo;