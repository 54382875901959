import axios from "axios";
import { getAid, getCurrentUid } from "../utils/Firebase";

const LOCAL_SERVER = "http://localhost:5001";
const LOCAL_MACBOOK = "http://192.168.0.179:5001";
const PROD_SERVER = "https://themindmap.azurewebsites.net";
const PROD_SERVER_OLD = "https://mindmap-service.onrender.com";

function getHost() {
  const hostname = window.location.hostname;
  if (hostname.includes("192.168")) {
    return LOCAL_MACBOOK;
  }
  return hostname.includes("localhost") ? LOCAL_SERVER : PROD_SERVER;
}

export async function getUserInfo() {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid;
  const aid = await getAid();
  const config = {
    headers: {
      "x-token": aid,
    },
  };
  const res = await axios.get(url, config);
  return res.data;
}

export async function addFriendForUser(friend) {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/friend";
  const res = await axios.post(url, friend);
  return res.data;
}

export async function deleteFriendForUser(fid) {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/friend/" + fid;
  const res = await axios.delete(url);
  return res.data;
}

export async function addFriendFact(fid, fact) {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/friend/" + fid + "/fact";
  const res = await axios.post(url, fact);
  return res.data;
}

export async function deleteFriendFact(fid, factId) {
  const uid = getCurrentUid();
  const url =
    getHost() + "/api/user/" + uid + "/friend/" + fid + "/fact/" + factId;
  const res = await axios.delete(url);
  return res.data;
}

export async function editFriend(friend) {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/friend";
  const res = await axios.put(url, friend);
  return res.data;
}

export async function getNotifications() {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/notifications";
  const res = await axios.get(url);
  return res.data;
}

export async function getUserProfle() {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/profile";
  const res = await axios.get(url);
  return res.data;
}

export async function signUpForEarlyAccess(email) {
  const url = getHost() + "/api/user/earlyaccess";
  const res = await axios.post(url, { email: email });
  return res.data;
}

export async function upgradeUser(licenseKey) {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/upgrade";
  const res = await axios.post(url, { licenseKey: licenseKey });
  return res.data;
}

export async function disableTelegramNotification() {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/notificationSettings";
  const res = await axios.post(url, { status: "DISABLED" });
  return res.data;
}

export async function updateCustomSetting(setting, status) {
  const uid = getCurrentUid();
  const url = getHost() + "/api/user/" + uid + "/customSettings";
  const res = await axios.post(url, { setting: setting, status: status });
  return res.data;
}
