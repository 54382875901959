import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import {signOut} from "../../utils/Firebase";
import { useHistory } from 'react-router'


const propTypes = {
    navPosition: PropTypes.string,
    hideNav: PropTypes.bool,
    hideSignin: PropTypes.bool,
    bottomOuterDivider: PropTypes.bool,
    bottomDivider: PropTypes.bool
}

const defaultProps = {
    navPosition: '',
    hideNav: false,
    hideSignin: false,
    bottomOuterDivider: false,
    bottomDivider: false
}

const Header = ({
                    className,
                    navPosition,
                    hideNav,
                    hideSignin,
                    bottomOuterDivider,
                    bottomDivider,
                    selected,
                    ...props
                }) => {

    const [isActive, setIsactive] = useState(false);
    const [current, setCurrent] = useState(selected ? selected : "Dashboard")

    const nav = useRef(null);
    const hamburger = useRef(null);
    const history = useHistory()
    console.log()
    useEffect(async () => {
        isActive && openMenu();
        document.addEventListener('keydown', keyPress);
        document.addEventListener('click', clickOutside);
        // const aid = await getAid()
        // console.log("aid=" + aid)
        return () => {
            document.removeEventListener('keydown', keyPress);
            document.removeEventListener('click', clickOutside);
            closeMenu();
        };
    });

    const openMenu = () => {
        document.body.classList.add('off-nav-is-active');
        nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
        setIsactive(true);
    }

    const closeMenu = () => {
        document.body.classList.remove('off-nav-is-active');
        nav.current && (nav.current.style.maxHeight = null);
        setIsactive(false);
    }

    const openDashboard = () => {
        closeMenu()
        setCurrent('Dashboard')
        // history.go(0)
        // window.location.reload()
    }

    const openNotifications = () => {
        closeMenu()
        setCurrent('Notification')
    }

    const openProfile = () => {
        closeMenu()
        setCurrent('Profile')
    }

    const keyPress = (e) => {
        isActive && e.keyCode === 27 && closeMenu();
    }

    const clickOutside = (e) => {
        if (!nav.current) return
        if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
        closeMenu();
    }

    const classes = classNames(
        'site-header',
        bottomOuterDivider && 'has-bottom-divider',
        className
    );


    function handleSignOut() {
        signOut()
            .then(() => {
                console.log("Signed Out");
            })
            .catch(e => {
                console.log("Error signing out", e);
            });
    }

    return (
        <header
            {...props}
            className={classes}
        >
            <div className="container">
                <div className={
                    classNames(
                        'site-header-inner',
                        bottomDivider && 'has-bottom-divider'
                    )}>
                    <Logo />

                    {!hideNav &&
                        <>
                            <button
                                ref={hamburger}
                                className="header-nav-toggle"
                                onClick={isActive ? closeMenu : openMenu}
                            >
                                <span className="screen-reader">Menu</span>
                                <span className="hamburger">
                  <span className="hamburger-inner"> </span>
                </span>
                            </button>
                            <nav
                                ref={nav}
                                className={
                                    classNames(
                                        'header-nav',
                                        isActive && 'is-active'
                                    )}>
                                <div className="header-nav-inner">
                                    
                                    <ul className={
                                        classNames(
                                        'list-reset text-xs',
                                        navPosition && `header-nav-${navPosition}`
                                        )}>
                                        <li>
                                        <Link to="/profile" 
                                            className={current === 'Profile' ? 'button button-primary button-wide-mobile button-sm' : ''} 
                                            onClick={openProfile}> 
                                                Profile
                                        </Link>
                                        </li>
                                    </ul>

                                    <ul className={
                                        classNames(
                                        'list-reset text-xs',
                                        navPosition && `header-nav-${navPosition}`
                                        )}>
                                        <li>
                                        <Link to="/dashboard"
                                            id="dashboardBtn" 
                                            className={current === 'Dashboard' ? 'button button-primary button-wide-mobile button-sm' : ''} 
                                            onClick={openDashboard}> 
                                                Dashboard
                                        </Link>
                                        </li>
                                    </ul>
                                    <ul className={
                                        classNames(
                                        'list-reset text-xs',
                                        navPosition && `header-nav-${navPosition}`
                                        )}>
                                        <li>
                                        <Link to="/notifications" 
                                            className={current === 'Notification' ? 'button button-primary button-wide-mobile button-sm' : ''} 
                                            onClick={openNotifications}> 
                                                Notification
                                        </Link>
                                        </li>
                                    </ul>
                               
                                    {!hideSignin &&
                                        <ul
                                            className="list-reset header-nav-right"
                                        >
                                            <li>
                                                <Link to="#0" className="button button-info button-wide-mobile button-sm" onClick={handleSignOut}>Sign Out</Link>
                                            </li>
                                        </ul>}
                                </div>
                            </nav>
                        </>}
                </div>
            </div>


        </header>
    );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
