import firebase from "firebase/compat/app";

import "firebase/compat/auth";

// if (!process.env.REACT_APP_FIREBASE_CONFIG) {
//   console.error("REACT_APP_FIREBASE_CONFIG must be defined");
//   console.log("ENV: ", process.env);
// }
const FIREBASE_CONF = {
  apiKey: "AIzaSyDwSdSNTyFcdMBiwzwDPuJ4SeFI402xqU0",
  authDomain: "mindmap-c29a1.firebaseapp.com",
  projectId: "mindmap-c29a1",
  storageBucket: "mindmap-c29a1.appspot.com",
  messagingSenderId: "27808033279",
  appId: "1:27808033279:web:8a3b28915547f077195fca",
  measurementId: "G-M96LCRRX3J",
};

export function initialize() {
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_CONF);
  }
}

export async function googleSignIn() {
  await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
}

export function attachAuthListener(handler) {
  return firebase.auth().onAuthStateChanged((user) => {
    handler(user);
  });
}

export async function createNewUser(email, password) {
  await firebase.auth().createUserWithEmailAndPassword(email, password);
}

export async function signIn(email, password) {
  await firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function signOut() {
  await firebase.auth().signOut();
}

export async function getAid() {
  return await firebase.auth()?.currentUser?.getIdToken();
}

export function getCurrentUid() {
  return firebase.auth()?.currentUser?.uid;
}

// https://github.com/asmitab/react-firebase-login
