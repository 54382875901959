import React from 'react';
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBIcon,
    MDBInput,
    MDBCheckbox
}
    from 'mdb-react-ui-kit';
import { MDBSpinner } from 'mdb-react-ui-kit';

import SectionHeader from "../partials/SectionHeader";
import { authStates, withAuth } from "../../auth";
import {Redirect} from "react-router-dom";
import {googleSignIn} from "../../../utils/Firebase";
function App({authState, user}) {

    const sectionHeader = {
        title: 'Welcome back. We exist to make relations better',
    };
    console.log("authState=" + authState);
    if (user) {
        console.log(user.displayName)
    }

    if (authState === authStates.INITIAL_VALUE) {
        return (
            <div className={"text-center"}>
                <MDBSpinner color='primary'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
        );
    }

    if (authState === authStates.LOGGED_IN) {
        return <Redirect to="/dashboard"></Redirect>;
    }

    return (
        <section style={{paddingTop: '5rem'}}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <MDBContainer fluid>
                <div className={"text-center"}>
                    <MDBBtn className="mb-2 w-80" size="lg" style={{backgroundColor: '#dd4b39'}} onClick={googleSignIn}>
                        <MDBIcon fab icon="google" className="mx-2"/>
                        Sign in with google
                    </MDBBtn>
                </div>

            <hr className="my-4" />
            </MDBContainer>
        </section>
    );
}

export default withAuth(App);