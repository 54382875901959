import React from 'react';
import DashboardHeader from '../components/layout/DashboardHeader';

const DashboardLayout = ({ children }) => (
    <>
        <DashboardHeader navPosition="right" className="reveal-from-bottom" />
        <main className="site-content">
            {children}
        </main>
 
    </>
);

export default DashboardLayout;