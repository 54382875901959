import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Banner from '../components/layout/partials/Banner';

const LayoutDefault = ({ children, ...props }) => {
  const hidebar = props?.hidebar
  const showbar = !hidebar

  return ( 
  <>
    {showbar && (
      <Header navPosition="right" className="reveal-from-bottom" />
    )}
    
    <main className="site-content">
      {children}
    </main>
    {/* <Banner /> */}
    <Footer />
  </>
  )
}
  

export default LayoutDefault;  