import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import DashboardLayout from "./layouts/DashboardLayout";
import NotificationLayout from './layouts/NotificationLayout';
import UserProfileLayout from './layouts/UserProfileLayout';

// Views 
import Home from './views/Home';
import ProfilePage from './components/sections/ProfilePage'
import Login from './components/sections/login/Login'
import Dashboard from "./components/sections/dashboard/Dashboard";
import Notifications from "./components/sections/notification/Notifications"
import UserProfile from "./components/sections/userprofile/UserProfile"
import Pricing from "./components/sections/pricing/Pricing"
import Upgrade from "./components/sections/pricing/Upgrade"
import Contact from "./components/sections/pricing/Contact"
// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/profile1" component={ProfilePage} layout={LayoutDefault}/>
          <AppRoute exact path="/login" component={Login} layout={LayoutDefault} />
          <AppRoute exact path="/dashboard" component={Dashboard} layout={DashboardLayout} />
          <AppRoute exact path="/notifications" component={Notifications} layout={NotificationLayout} />
          <AppRoute exact path="/profile" component={UserProfile} layout={UserProfileLayout} />
          <AppRoute exact path="/upgrade" component={Upgrade} layout={LayoutDefault} hidebar={true} />
          <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault} hidebar={true} />
        </Switch>
      )} />
  );
}

export default App;