import React, {useState} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import classNames from 'classnames';
import {MDBInput, MDBBtn} from "mdb-react-ui-kit";
import { SectionProps } from '../../../utils/SectionProps';
import { Link } from 'react-router-dom';
const propTypes = {
    ...SectionProps.types
  }
  
  const defaultProps = {
    ...SectionProps.defaults
  }

function Contact({ 
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
      );

    
    return (
        <section
        {...props}
        className={outerClasses}
      >
             <div className="container-sm">
               <div className={innerClasses}>
                <div className="hero-content">
                  <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                    Contact <span className="text-color-primary">Mindmap</span>
                  </h2>
                  <div className="container-xs">
                    <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                        Mindmap is currently managed by a solo developer.
                        <br/>
                        <br/>
                        Please write to us @ <i><a href = "mailto: thatman.dev121@gmail.com">thatman.dev121@gmail.com</a></i>
                   </p>
                  </div>
                  <div className='d-flex justify-content-center mb-2 mt-5'>
                        <Link to="/dashboard">
                            <MDBBtn outline className='btn mx-2' color="light" type="submit">Go to Dashboard</MDBBtn>
                        </Link>
                      </div>
                </div>
              </div>
             </div>
             <Toaster />
        </section>
        
    )
}

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;
export default Contact;