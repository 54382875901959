import React, {useState, useEffect} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {Redirect} from "react-router-dom";
import {authStates, withAuth} from "../../auth";
import {MDBContainer, MDBCol, MDBRow, MDBSpinner, MDBCardBody, MDBCard} from "mdb-react-ui-kit";
import {getNotifications} from "../../../utils/Client";

const MONTH_MAP = {
    1: "January",
    2: "Februrary",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
}
function Notifications({authState, user}) {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 868px)").matches
    )
    const [showLoader, setShowLoader] = useState(false)
    const [currentNotifications, setCurrentNotifications] = useState([])

    useEffect(() => {
        window
            .matchMedia("(min-width: 868px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);
    useEffect(async () => {
        if (authState === authStates.LOGGED_IN) {
            try {
                setShowLoader(true)
                const notifications = await getNotifications()
                setCurrentNotifications(notifications)
                setShowLoader(false)
            } catch(err) {
                toast.error('Error while fetching notifications');
                setShowLoader(false)
            }
        }
    }, [authState])

    if (authState === authStates.INITIAL_VALUE) {
        return (
            <div className={"text-center"}>
                <MDBSpinner color='primary'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
        );
    }

    if (authState === authStates.LOGGED_OUT) {
        return <Redirect to="/login"></Redirect>;
    }


    const getDateStr = (date) => {
        const split = date.split("/")
        const monthName = MONTH_MAP[parseInt(split[1])]
        return split[0] + " " + monthName
    }

    const ShowNotifications = ({notifications}) => {
        return (
          <>
          {notifications.map((ntf, index) => {
              console.log(ntf)
              if (!ntf || !ntf.fact) {
                return <></>
              }
              let titletext = ntf['fact']['title']
              if (ntf.fact.type === "TIMED" && ntf.fact.date) {
                  titletext += " coming on "
              }
              let dtStr = getDateStr(ntf['fact'].date)
              return  <MDBCard key={index} style={{backgroundColor: '#F2F2F2', borderRadius: '0rem'}} className={(index === 0) ? '' : 'mt-1'}>
                           <MDBCardBody style={{padding: '0.8rem 1.5rem'}}>
                              <p className="text-muted mb-1">{titletext} <span style={{fontWeight: "bold"}}>{dtStr}</span></p>
                              <div style={{textAlign: "right"}}>
                                {ntf.friendName}
                              </div>
                          </MDBCardBody>
                       </MDBCard>
          
          })}
          </>           
        )                       
    }

    const showWeekly = currentNotifications && currentNotifications.nextWeek && currentNotifications.nextWeek.length > 0
    const showMonthly = currentNotifications && currentNotifications.nextMonth && currentNotifications.nextMonth.length > 0

    return (
        <MDBContainer fluid style={(matches)? {paddingLeft: '7rem', paddingRight: '7rem', paddingTop: '4rem', paddingBottom: '2rem'} : {paddingTop: '6rem', paddingBottom: '2rem'}}>
             {showLoader && (
                <div className={"text-center"}
                     style={{position: 'fixed', left: '0x', top: '30%', width: '100%', height: '100%', zIndex: '9999'}}>
                    <MDBSpinner color='primary'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>
            )}
            <MDBRow>
                    <MDBCol lg="6">
                        <h5>Upcoming Next Week 🔔 🔔</h5>
                        {showWeekly && (
                            <ShowNotifications notifications={currentNotifications.nextWeek}/>
                        )}
                        {!showWeekly && (
                            <div style={{textTransform: "unset", border: "0.5px solid", borderStyle: "dashed", width:"100%", padding: "2rem"}}>
                                You have no new notifications here
                            </div>
                        )}
                    </MDBCol>
                    <MDBCol lg="6">
                        <h5>Upcoming Next Month 🔔</h5>
                        {showMonthly && (
                            <ShowNotifications notifications={currentNotifications.nextMonth}/>
                        )}
                        {!showMonthly && (
                            <div style={{textTransform: "unset", border: "0.5px solid", borderStyle: "dashed", width:"100%", padding: "2rem"}}>
                                You have no new notifications here
                            </div>
                        )}
                    </MDBCol>
            </MDBRow>
        <Toaster />
        </MDBContainer>
    )
}

export default withAuth(Notifications);